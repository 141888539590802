$(document).ready(function() {

    /**
     * After show the components of a product.
     */
    /*$(document).on('shown.bs.collapse', '[data-product-components-container]', function (event) {

     event.stopPropagation();

     var collapsableElement = $(this);

     // Scroll to the element
     modules.main.scrollToElement(collapsableElement.find('.start-of-components:first'));

     });*/

    /**
     * Before show components of a product
     */
    $(document).on('show.bs.collapse', '[data-product-components-container]', function (event) {

        event.stopPropagation();

        var collapsableElement = $(this);

        $('[data-product-components-container]').each(function (i, obj) {

            var elem = $(this);

            if (elem.attr('id') != collapsableElement.attr('id')) {
                elem.collapse('hide');
            }
        });

        var collapsableElementTrigger = $('[data-toggle][data-target="#' + collapsableElement.attr('id') + '"]');

        // Change the Label
        collapsableElementTrigger.text(collapsableElementTrigger.attr('data-components-shown'));

        // Manage the product-row of this product.
        var rowContainer = collapsableElementTrigger.closest('.products-list-row');
        rowContainer.addClass('components-shown');

        collapsableElement.find('.open-components-triangle').fadeIn();

    });

    // Before hide the components of a product.
    $(document).on('hide.bs.collapse', '[data-product-components-container]', function(event) {

        event.stopPropagation();

        var collapsableElement = $(this);

        collapsableElement.find('.open-components-triangle').fadeOut();

        var collapsableElementTrigger = $('[data-toggle][data-target="#' + collapsableElement.attr('id') + '"]');

        // Change the Label
        collapsableElementTrigger.text(collapsableElementTrigger.attr('data-components-hidden'));

        // Manage the product-row of this product.
        var rowContainer = collapsableElementTrigger.closest('.products-list-row');
        rowContainer.removeClass('components-shown');

    });

    // if item has added in shopping cart url parameter, show the small cart
    if ($('#itemAdded').length) {
        updateSmallShoppingCart();
        var cartPopup = $('#shopping-cart-popup-outer');
        if (!cartPopup.is(":visible")) {
            modules.header.openCart();
        }
        $('#shopping-cart-popup-outer .items-notifications.add-item-notification').fadeIn().delay(1000).fadeOut();
        var openTimeCheck = cartPopup.attr("data-open-time");
        setTimeout(function() {
            modules.header.closeCart(null, true, openTimeCheck);
        }, 4000);
    };

    /**
     * When the user enters checkout page
     */
    if ($('#track-checkout-js').length) {
        modules.facebookPixelTracking.initiateCheckout();
    }

    /**
     * When the user enters checkout page and not buy articles
     */
    if ($('#checkoutNotBuyArticles').length) {
        $('#checkoutNotBuyArticles').modal({ show: true });
    }

    /**
     * Track purchase
     */
    $(document).on('click', '[data-track-purchase]', function(e) {
        var value = $(this).attr('data-track-purchase');
        if (value) {
            modules.facebookPixelTracking.purchase(value, 'EUR');
        }
    });

    /**
     * Clean shopping cart when user goes to checkout
     */
    $(document).on('click', '[data-go-checkout-flag]', function(e) {
        var url = '/store/api/shopping-cart/remove-all-items';

        $.ajax({
            url: url,
            context: document.body
        }).done(function() {
            $('span.total-cart-items').html('');
        });
    });

    setupAddProductComment();

    setupRecommendProductPerEmail();

    updateSmallShoppingCartNumberItems();

    /**
     * STORE Tooltips
     */
    $('.order-resume-container .purchase-term-info').qtip({
        content: {
            text: $('.order-resume-container .purchase-term-info-text').attr('data-text')
        },
        style: {
            classes: 'qtip-blue'
        }
    })
});

/**
 * SHOPPING CART
 */
function updateSmallShoppingCartNumberItems() {
    $.ajax({
        type: "GET",
        url: '/store/api/shopping-cart/get-items',
        contentType: "application/json",
        cache: false,
        dataType: 'json',
        success: function(data) {
            if (data) {
                var qtdTotalCesto = data.qtd_total_cesto;
                $('span.total-cart-items').html('');

                if (typeof qtdTotalCesto !== "undefined") {
                    if (qtdTotalCesto != 0) {
                        $('span.total-cart-items').html(qtdTotalCesto);
                        $('li#shopping-cart-header-btn').removeClass('empty-shopping-cart');
                    }
                }
            } else {
                $('li#shopping-cart-header-btn').addClass('empty-shopping-cart');
            }

        },
        error: function(jqxhr) {
            // @todo to implement error
        }
    });
}

var stock24hDescricaoEmStock;

function updateSmallShoppingCart(callback) {
    $.ajax({
        type: "GET",
        url: '/store/api/shopping-cart/get-items',
        contentType: "application/json",
        cache: false,
        dataType: 'json',
        success: function(data) {

            var products = null;
            var totalProducts = 0;
            $('#shopping-cart-popup-no-items').hide();
            $('#shopping-cart-popup').hide();

            if (data) {
                if (data.linha_cesto) {
                    var totalProducts = data.linha_cesto.length;
                    var products = data.linha_cesto;
                }

                var totalPrice = data.valor_total;
                var totalDiscount = data.poupanca_cesto;

                var productListHtml = "";

                // update values in the popup
                $('#shopping-cart-popup .num-items').html(totalProducts);
                $('#shopping-cart-popup .total-value').html(modules.main.numberFormat(totalPrice));
                if (parseInt(totalDiscount) === 0) {
                    $(".items-container.second-row").hide();
                    $(".items-container.first-row").addClass('no-savings');
                } else {
                    $('#shopping-cart-popup .savings-value').html(modules.main.numberFormat(totalDiscount));
                    $(".items-container.second-row").show();
                    $(".items-container.first-row").removeClass('no-savings');
                }

                var shoppingCartDiasDisponibilidade = 0;
                var shoppingCartFreePostage = true;

                for (var i = 0; i < totalProducts; i++) {

                    //@todo Please move the product html creation to server side | including validation if it's ebook using twig function isEbook
                    var titulo = products[i].descricao,
                        isEbook = products[i].tipo_artigo == 619 ? true : false,
                        disabledInputAttr = isEbook ? 'disabled="disabled"' : '',
                        itemQuantDisabled = isEbook ? 'disabled' : '';
                    titulo += isEbook ? " (eBook)" : "";

                    productListHtml += "<div class='row'><div class='col-lg-12 clearfix'><div class='item-image'><img width='65px' height='100px' src='";
                    //productListHtml += modules.main.updateUrlWithQueryString(products[i].gallery_u_r_l, 'with=65');
                    productListHtml += products[i].gallery_u_r_l + '/65x';					
                    productListHtml += "'>";
                    productListHtml += "</div><div class='item-description'><div class='item-title'>";
                    productListHtml += modules.main.truncateString(titulo, 35, '...');
                    productListHtml += "</div><div class='item-quant " + itemQuantDisabled + "'>";
                    productListHtml += "<form name='form-item-{{ item.sub_art_id }}' id='" + products[i].sub_art_id + "' class='change-quant-form'>";
                    productListHtml += "<input " + disabledInputAttr + "type='number' class='small-shopping-cart-alter-quant' id='item-" + products[i].sub_art_id + "-qtd' name='item-" + products[i].sub_art_id + "-qtd' value='" + products[i].qtd + "' data-item-id='" + products[i].sub_art_id + "' maxlength='2'>";
                    productListHtml += "</form>";
                    productListHtml += "</div></div><div class='item-price-container'><div class='item-price'><span class='item-price-value'>";
                    productListHtml += modules.main.numberFormat(products[i].total_linha);
                    productListHtml += "</span><span class='item-price-currency'>" + modules.main.currency() + "</span></div>";
                    productListHtml += "<div class='item-remove-action'>";
                    productListHtml += "<div class='item-remove-icon' data-prod-id='" + products[i].sub_art_id + "' data-prod-quant='" + products[i].qtd + "'>X</div>";
                    productListHtml += "</div></div></div></div>";

                    // calcular dias de disponibilidade do cesto
                    if (products[i].dias_disponibilidade > shoppingCartDiasDisponibilidade) {
                        shoppingCartDiasDisponibilidade = products[i].dias_disponibilidade;
                    }

                    // calcular se cesto tem portes gratis
                    if (products[i].txa_desc_port != 100) {
                        shoppingCartFreePostage = false;
                    }
                }

                // mostrar disponibilidade do cesto
                if (data.flg_escolar != 'S') {
                    if (shoppingCartDiasDisponibilidade > 0) {
                        if (shoppingCartDiasDisponibilidade < 3) {
                            var shoppingCartDiasDisponibilidadeText;
                            if (stock24hDescricaoEmStock == 'S') {
                                shoppingCartDiasDisponibilidadeText = 'Em Stock';
                            }
                            else {
                                shoppingCartDiasDisponibilidadeText = 'Envio em ' + shoppingCartDiasDisponibilidade * 24 + ' horas';
                            }
                            $('#shopping-cart-popup .postage-time-label').html(shoppingCartDiasDisponibilidadeText);
                        } else {
                            var shoppingCartDiasDisponibilidadeText = 'Envio em ' + shoppingCartDiasDisponibilidade + ' dias';
                            $('#shopping-cart-popup .postage-time-label').html(shoppingCartDiasDisponibilidadeText);
                        }
                    } else {
                        $('#shopping-cart-popup .postage-time-label').html('Disponibilidade imediata');
                    }
                }
                else {
                    $('#shopping-cart-popup .postage-time-label').parent().parent().addClass('hidden');
                }

                // update shopping cart count
                if (data.qtd_total_cesto != '0') {
                    $('li#shopping-cart-header-btn').removeClass('empty-shopping-cart');
                    $('span.total-cart-items').html(data.qtd_total_cesto);
                } else {
                    $('li#shopping-cart-header-btn').addClass('empty-shopping-cart');
                    $('span.total-cart-items').html('');
                }


                if (shoppingCartFreePostage) {
                    $('#shopping-cart-popup .items-container.third-row').show();
                    $('#shopping-cart-popup .fourth-row').removeClass('negative-m-top');
                } else {
                    $('#shopping-cart-popup .items-container.third-row').hide();
                    $('#shopping-cart-popup .fourth-row').addClass('negative-m-top');
                }
            } else {
                $('li#shopping-cart-header-btn').addClass('empty-shopping-cart');
                $('span.total-cart-items').html('');
            }

            if (totalProducts == 0) {
                $('#shopping-cart-popup-no-items').show();
            } else {
                $('#shopping-cart-popup').show();
                $('#shopping-cart-popup .small-shopping-cart-item').html(productListHtml);
                modules.lazyLoad.init($('#shopping-cart-popup .small-shopping-cart-item .lazy'), {});
            }

            if (typeof callback === "function") {
                callback();
            }

        },
        error: function(jqxhr) {
            // to implement error
        }
    });
}

//Scroll header cart lazy load for book covers
$(document).on('scroll', '.small-shopping-cart-item', function() {
    $(document).trigger("vo-lazy-check");
});

// click the small shopping cart remove item
$(document).on('click', '.item-remove-icon', function(e) {
    e.preventDefault();
    e.stopPropagation();
    modules.header.cartPreventTimeoutClose();
    var prodIdToRemove = $(this).attr('data-prod-id');
    var prodQuantToRemove = $(this).attr('data-prod-quant');

    $.ajax({
        type: "GET",
        url: '/store/api/shopping-cart/remove-item/' + prodIdToRemove + '/' + prodQuantToRemove,
        contentType: "application/json",
        cache: false,
        dataType: 'json',
        success: function(data) {
            updateSmallShoppingCart();
            var productImage = $('[data-product-image-container="' + prodIdToRemove + '"]');
            productImage.find('.product-to-cart-btn').hide();
            productImage.find('.product-to-checkout-btn').hide();
            productImage.find('.product-to-buy-btn').show();

            if ($("#shopping-cart-popup .small-shopping-cart-item>.row").size() > 1) {
                //Show if not last item
                $('#shopping-cart-popup .items-notifications.remove-item-notification').fadeIn().delay(1000).fadeOut();
            }
        },
        error: function(jqxhr) {
            // to implement error
        }
    });
});

/**
 * product list page
 */
// product list add  item
$(document).on('click', '.product-list-buy-button', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var clickedItem = $(this);
    addToCart(clickedItem.attr('data-productId'), clickedItem.attr('data-productQuant'), clickedItem.attr('data-validationKey'), clickedItem)
});

function addToCart(productId, quantity, validationKey, trigger) {

    var url = '/store/api/shopping-cart/add-items/' + productId + '/' + quantity + '/' + validationKey;

    $.ajaxQueue({
        type: "GET",
        url: url,
        contentType: "application/json",
        cache: false,
        success: function(data) {
            modules.facebookPixelTracking.addToCart({ 'value': productId });
            var addToCartCallback = function() {
                var cartPopup = $('#shopping-cart-popup-outer');

                if (modules.main.getDevice() !== "mobile") {
                    if (!cartPopup.is(":visible")) {
                        modules.header.openCart();
                    }
                }


                if (trigger) {
                    var productImage = trigger.closest('.product-image');
                    productImage.find('.product-to-cart-btn').show();
                    productImage.find('.product-to-checkout-btn').show();
                    productImage.find('.product-to-buy-btn').hide();

                    var productImage = trigger.closest('.product-info-img-container');
                    productImage.find('.product-to-cart-btn').show();
                    productImage.find('.product-to-checkout-btn').show();
                    productImage.find('.product-to-buy-btn').hide();
                }

                $('.items-notifications.add-item-notification').fadeIn().delay(2500).fadeOut();

                var openTimeCheck = cartPopup.attr("data-open-time");
                if (modules.main.getDevice() !== "mobile") {
                    setTimeout(function() {
                        modules.header.closeCart(null, true, openTimeCheck);
                    }, 4000);
                }
            }
            updateSmallShoppingCart(addToCartCallback);

        },
        error: function(jqxhr) {
            // to implement error
        }
    });


}

/**
 * product detail page
 */
// product detail formato
$(".product-formats-item").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var value = $(this).attr('data-link');
    if (value) {
        window.location.href = value;
    }
});
// product detail add item
$(".product-detail-buy-button.enabled").click(function(e) {
    e.preventDefault();
    e.stopPropagation();

    if ($('#modal-product-cannot-buy-books-info').length > 0) {
        $('#modal-product-cannot-buy-books-info').modal('show');
        return;
    }

    var productId = $(this).attr('data-productId');

    var url = '/store/api/shopping-cart/add-items/' + productId + '/' + $(this).attr('data-productQuant') + '/' + $(this).attr('data-validationKey');

    $.ajaxQueue({
        url: url,
        context: document.body
    }).done(function() {
        modules.facebookPixelTracking.addToCart({ 'value': productId });
        var openCartCallback = function() {
            if (modules.main.getDevice() !== "mobile") {
                if (!$('#shopping-cart-popup-outer').is(":visible")) {
                    modules.header.openCart();
                }
            }
            $('.items-notifications.add-item-notification').fadeIn();
            $('.items-notifications.add-item-notification').delay(2500).fadeOut();

            if (modules.main.getDevice() !== "mobile") {
                setTimeout(function() {
                    modules.header.closeCart();
                }, 2500);
            }
        };

        updateSmallShoppingCart(openCartCallback);

    });
});
// shopping cart remove item
$(".shopping-cart-item-remove").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $.ajax({
        url: $(this).attr('href'),
        context: document.body
    }).done(function() {
        location.reload();
    });
});
// shopping cart page - alter quantity
$(document).on('submit', '.change-quant-form', function(e) {
    return false;
});

$(document).on('focus', '.small-shopping-cart-alter-quant', function() {
    //block cart close effect
    modules.header.cartPreventTimeoutClose();
});

$(document).on('click', '.small-shopping-cart-alter-quant', function() {
    $(this).select();
});

$(document).on('change', '.small-shopping-cart-alter-quant', function() {

    // block form submitting
    $(this).parent().submit(function(e) {
        e.preventDefault();
    });

    var prodId = $(this).attr('data-item-id');
    var prodQuant = $(this).val();
    var url = '/store/api/shopping-cart/alter-quantity/' + prodId + '/' + prodQuant;

    $.ajax({
        type: "GET",
        url: url,
        cache: false,
        dataType: 'json',
        success: function(data) {
            if (!parseInt(prodQuant)) {
                var productImage = $('[data-product-image-container="' + prodId + '"]');
                productImage.find('.product-to-cart-btn').hide();
                productImage.find('.product-to-checkout-btn').hide();
                productImage.find('.product-to-buy-btn').show();
            }
            updateSmallShoppingCart();
            if (data == 'Cesto Vazio') {
                $('span.total-cart-items').html(0);
            } else {
                $('span.total-cart-items').html(data.qtd_total_cesto);
            }
        }
    });
});
$('.shopping-cart-alter-quant').change(function(e) {

    // block form submitting
    $(this).parent().submit(function(e) {
        e.preventDefault();
    });

    var prodId = $(this).attr('data-item-id');
    var prodQuant = $(this).val();
    var url = '/store/api/shopping-cart/alter-quantity/' + prodId + '/' + prodQuant;

    $.ajax({
        url: url,
        context: document.body
    }).done(function() {
        location.reload();
    });
});

// product detail
$("#view-more-comments-lnk").click(function(e) {
    e.preventDefault();
    $('.product-comment-row[show-on-load="false"]').fadeIn("slow");
    $('.view-more-container').hide();
    $('.view-less-container').fadeIn("slow");
});
$("#view-less-comments-lnk").click(function(e) {
    e.preventDefault();

    var target = $('#comments-anchor');
    var targetOffset = $('#comments-anchor').offset().top - 80;

    $('html, body').stop().animate({
        'scrollTop': targetOffset
    }, 500, 'swing', function() {
        window.location.hash = target;

        $('.product-comment-row[show-on-load="false"]').fadeOut("slow");
        $('.view-less-container').hide();
        $('.view-more-container').fadeIn("slow");
    });
});

$(document).on('click', '.product-list-view-more-btn', function(e) {

    e.preventDefault();
    e.stopPropagation();

    var url = $(this).attr('href'),
        currentPage = $(this).attr('data-current-page'),
        nextPage = $(this).attr('data-next-page'),
        triggerContainer = $(this).closest('.product-list-view-more-container');

    $.ajax({
        type: "GET",
        url: url,
        beforeSend: function(xhr) {
            //Hide Button
            triggerContainer.addClass("hidden");
            // Show the loader
            $(".product-list-pagination-loader-" + currentPage).removeClass('hidden');
            // Hide error message
            $(".product-list-pagination-error-" + currentPage).addClass('hidden');

        },
        success: function(data) {

            // Remove the last class
            $('.store-product-list').find('.product-row-wrapper.last').removeClass('last');

            // Hide the loader
            $(".product-list-pagination-loader-" + currentPage).addClass('hidden');
            $("#product-list-pagination-" + currentPage).next().fadeIn();
            $("#product-list-pagination-" + currentPage).remove();
            $("#product-list-page-" + currentPage).after(data);
            $("#product-list-page-" + nextPage).hide().css('opacity', 0).slideDown('slow').animate({ opacity: 1 }, { queue: false, duration: 'slow' });

            triggerContainer.removeClass("error-loading");
            modules.lazyLoad.init();
        },
        error: function(jqxhr) {
            // Hide the loader
            $(".product-list-pagination-loader-" + currentPage).addClass('hidden');
            // Show the error message
            $(".product-list-pagination-error-" + currentPage).removeClass('hidden');
            triggerContainer.addClass("error-loading");
        },
        complete: function() {
            triggerContainer.removeClass("hidden");
        }
    });
});
$("#shareEmailForm").validate();
$("#shareEmailButtonSubmit").click(function(e) {
    $("#shareEmailForm").submit();
});

/**
 *  Product detail media modal
 */
$('#prd-video-lnk').click(function(e) {
    $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
    $('div[id^="video-container-"]').first().show();
});
$('#prd-sound-lnk').click(function(e) {
    $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
    $('div[id^="audio-container-"]').first().show();
});
$('#prd-image-lnk').click(function(e) {
    $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
    $('div[id^="image-container-"]').first().show();
});
$('div[id^="media-modal-video-lnk-"]').click(function(e) {
    if (!$(this).hasClass('inactive')) {
        $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
        $('#video-container-'+$(this).data('id')).fadeIn();
    }
});
$('div[id^="media-modal-sound-lnk-"]').click(function(e) {
    if (!$(this).hasClass('inactive')) {
        $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
        $('#audio-container-'+$(this).data('id')).fadeIn();
    }
});
$('div[id^="media-modal-image-lnk-"]').click(function(e) {
    if (!$(this).hasClass('inactive')) {
        $('div[id^="video-container-"], div[id^="audio-container-"], div[id^="image-container-"]').hide();
        $('#image-container-'+$(this).data('id')).fadeIn();
    }
});

function setupAddProductComment() {

    var validator = modules.validator.prepareValidator($('#addProductCommentModalForm'));

    $('#addProductCommentModal').on('hidden.bs.modal', function(e) {
        $('#addProductCommentModalForm .clear-btn').click();
        $('#addProductCommentModalFormContainer').show();
        $('#addProductCommentModalForm-error-message').hide();
        $('#addProductCommentModalFormSuccessContainer').hide();
        $('#addProductCommentModal').removeClass('modal-vo-default');
    });

    var $rateYo = $("#rate-product").rateYo({
        fullStar: true,
        spacing: "4px",
        starWidth: "18px",
        normalFill: "#FFF",
        ratedFill: "#95c731",
        onSet: function(rating, rateYoInstance) {
            $('#addProductCommentModalForm-rate').val(rating);
            $('#addProductCommentModalForm-rate-error').remove();
        }
    });

    $('#rate-product .jq-ry-normal-group').find("polygon").attr({ 'style': "stroke:#95c731;stroke-width:30;" });

    $('#addProductCommentModalForm').on('ajax-form-submit:beforesend', function(e, xhr) {
        $('#addProductCommentModalFormContainer').show();
        $('#addProductCommentModalForm-error-message').hide();
        $('#addProductCommentModalFormSuccessContainer').hide();
    });

    $('#addProductCommentModalForm').on('ajax-form-submit:done', function(e, data, textStatus, jqXHR) {
        $('#addProductCommentModalForm .clear-btn').click();
        $('#addProductCommentModalFormContainer').hide();
        $('#addProductCommentModal').addClass('modal-vo-default');
        $('#addProductCommentModalFormSuccessContainer').show();
    });

    $('#addProductCommentModalForm').on('ajax-form-submit:fail', function(e, jqXHR, textStatus, errorThrown) {
        $('#addProductCommentModalForm-error-message').fadeIn('slow');
        $('#addProductCommentModalFormSuccessContainer').hide();
    });

    $('#addProductCommentModalForm .clear-btn').on('click', function(e) {
        validator.resetForm();
        $rateYo.rateYo("rating", 0);
        $('#addProductCommentModalForm-rate').val(0);
    });
}

function setupRecommendProductPerEmail() {

    modules.validator.setDefaults();

    if ($('#socialMailModalForm').length) {
        
        modules.validator.prepareValidator($('#socialMailModalForm'));

        $('#socialMailModalForm').on('change', function () {
            if ($('#socialMailModalForm').valid()) {
                if (($('.g-recaptcha').length && grecaptcha.getResponse()) || !$('.g-recaptcha').length) {
                    $('.button.submit-btn').removeClass('disabled');
                }
            }
            else {
                $('.button.submit-btn').addClass('disabled');                
            }
        });

        //Verify recaptcha
        if (!modules.main.isTestEnvironment()) {
            if ($('.g-recaptcha').length) {
                $("#hiddenRecaptcha").rules("add", {
                    reCaptchaMethod: true
                });
            }
        }

        // Clean button text //
        $('.button.clear-btn').click(function () {
            $('.button.submit-btn').addClass('disabled');
            if ($('.g-recaptcha').length) {
                grecaptcha.reset();
            }
        });

        $('#socialMailModal').on('hidden.bs.modal', function (e) {

            $('#socialMailModalForm .clear-btn').click();
            $('#socialMailModalForm-error-message').hide();
            $('#socialMailModalForm-success-message').hide();

            if ($('.g-recaptcha').length) {
                grecaptcha.reset();
            }
        });

        $('#socialMailModalForm').on('ajax-form-submit:beforesend', function (e, xhr) {
            $('#socialMailModalForm-error-message').hide();
            $('#socialMailModalForm-success-message').hide();
        });

        $('#socialMailModalForm').on('ajax-form-submit:done', function (e, data, textStatus, jqXHR) {
            $('#socialMailModalForm-success-message').fadeIn('slow');
            $('#socialMailModalForm-error-message').hide();
            $('#socialMailModalForm .clear-btn').click();
            $('.button.submit-btn').addClass('disabled');
            if ($('.g-recaptcha').length) {
                grecaptcha.reset();
            }
        });

        $('#socialMailModalForm').on('ajax-form-submit:fail', function (e, jqXHR, textStatus, errorThrown) {
            $('#socialMailModalForm-error-message').fadeIn('slow');
            $('#socialMailModalForm-success-message').hide();
            if ($('.g-recaptcha').length) {
                grecaptcha.reset();
            }
        });
    }

}

var recaptchaOnSuccess = function (response) {
    var errorDivs = document.getElementsByClassName("recaptcha-error");
    if (errorDivs.length) {
        errorDivs[0].className = "reCaptcha";
    }

    var errorMsgs = document.getElementsByClassName("recaptcha-error-message");
    if (errorMsgs.length) {
        errorMsgs[0].parentNode.removeChild(errorMsgs[0]);
        $('.button.submit-btn').addClass('disabled');
    } else {
        $('#socialMailModalForm').trigger('change');
    }
};


/**
 * Filters link redirection
 */
$(document).on('click', '.filters-item-link', function(e) {
    var value = $(this).attr('data-link');
    if (value) {
        window.location.href = value;
    }
});
/**
 * Audio player
 */
var installAudioControls = function installAudioControls() {
    var audioPlayer = document.getElementById('audio-player');

    if (audioPlayer) {
        var durationSec = new Number();
        var durationMin = new Number();

        durationSec = Math.floor(audioPlayer.duration);
        durationMin = Math.floor(durationSec / 60);
        durationMin = durationMin >= 10 ? durationMin : '0' + durationMin;
        durationSec = Math.floor(durationSec % 60);
        durationSec = durationSec >= 10 ? durationSec : '0' + durationSec;
        var durationString = durationMin + ":" + durationSec;
        $('#audio-file-duration').text(durationString);

        document.getElementById('audio-play').onclick = function() {
            if (audioPlayer.paused) {
                audioPlayer.play();
            } else {
                audioPlayer.pause();
            }

        }

        audioPlayer.addEventListener('timeupdate', function() {
            var currentTimeSec = new Number();
            var currentTimeMin = new Number();

            currentTimeSec = Math.floor(this.currentTime);
            currentTimeMin = Math.floor(currentTimeSec / 60);
            currentTimeMin = currentTimeMin >= 10 ? currentTimeMin : '0' + currentTimeMin;
            currentTimeSec = Math.floor(currentTimeSec % 60);
            currentTimeSec = currentTimeSec >= 10 ? currentTimeSec : '0' + currentTimeSec;
            var durationString = currentTimeMin + ":" + currentTimeSec;
            $('#audio-file-current-time').text(durationString);

            var percentage = this.currentTime / this.duration * 100;
            document.getElementById('audio-bar').style.width = percentage + "%";
        }, false);
    }
}
window.onload = installAudioControls;
